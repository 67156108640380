<template>
  <div
    class="menu-wrapper"
    :class="{ 'layout-sidebar-active': sidebarActive }"
    @click="onMenuClick"
    @mouseover="onSidebarMouseOver"
    @mouseleave="onSidebarMouseLeave"
  >
    <div class="sidebar-logo">
      <router-link to="/home">
        <img
          src="assets/layout/images/logo-freya-single.svg"
          alt="freya-layout"
        />
      </router-link>
      <a class="sidebar-pin" @click="onToggleMenu($event)">
        <span class="pin"></span>
      </a>
    </div>

    <div class="layout-menu-container">
      <AppSubmenu
        class="layout-menu"
        :items="menu"
        :layoutMode="layoutMode"
        :parentMenuItemActive="true"
        :menuActive="menuActive"
        :mobileMenuActive="mobileMenuActive"
        :root="true"
        @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick"
      />
    </div>
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu';
export default {
  name: 'AppMenu',
  emits: [
    'menu-click',
    'menuitem-click',
    'root-menuitem-click',
    'toggle-menu',
    'sidebar-mouse-over',
    'sidebar-mouse-leave',
  ],
  props: {
    sidebarActive: Boolean,
    sidebarStatic: Boolean,
    layoutMode: String,
    menuActive: Boolean,
    mobileMenuActive: Boolean,
  },
  data() {
    return {
      menu: [],
    };
  },
  created() {
    this.loadMenuByUser();
  },
  methods: {
    async loadMenuByUser() {
      try {
        this.menu = await this.$store.dispatch('loadMenybyUser', 
        ); //viene de la clases action.js G
        console.log(menu);
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        //console.log(error.message);
      }
    },
    onSidebarMouseOver() {
      if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
        this.$emit('sidebar-mouse-over');
      }
    },
    onSidebarMouseLeave() {
      if (this.layoutMode === 'sidebar' && !this.sidebarStatic) {
        setTimeout(() => {
          this.$emit('sidebar-mouse-leave');
        }, 250);
      }
    },
    onToggleMenu(event) {
      this.$emit('toggle-menu', event);
    },
    onMenuClick(event) {
      this.$emit('menu-click', event);
    },
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    },
    onRootMenuItemClick(event) {
      this.$emit('root-menuitem-click', event);
    },
  },
  components: { AppSubmenu },
};
</script>
