import API_DOMAIN from '../../../config';

export default {
  /******************************************************************
   *************RECUPERACION DE LISTA DE VALORES COMPAÑIAS***********
   ******************************************************************
   */
  async loadListValuesCiatab(context) {
    const token = context.rootGetters.token;
    let responseData;
    console.time('Tiempo de respuesta'); // Iniciar temporizador
    /*for (let retry = 0; retry < 6; retry++) {*/
    const response = await fetch(`${API_DOMAIN}/api/mosi/listado/Ciatab`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    if (response.ok) {
      responseData = await response.json();
      /*if (responseData.ciatab && responseData.ciatab.length > 0) {
          break;
        }*/
    }

    /*if (retry < 5) {
        console.log(retry);
        await new Promise((resolve) => setTimeout(resolve, 600)); // Esperar 1 segundo antes de volver a intentar
      }*/
    //}

    const companias = responseData.ciatab.map((item) => ({
      ciacod: item.ciacod,
      cianombre: item.cianombre,
      cianit: item.cianit,
      ciadir: item.ciadir,
    }));

    console.log(companias);
    context.commit('setListvalues', companias);
    context.commit('setFetchTimestamp');
    console.timeEnd('Tiempo de respuesta'); // Fin del temporizador y mostrar el tiempo
    // ////console.log(
    // //  'Inicio de Metodo de Recuperacion de LISTA DE VALORES COMPANIAS.'
    // //);
    // const token = context.rootGetters.token;
    // ////console.log('Muestra de token desde otra pantalla');
    // ////console.log(token);
    // const response = await fetch(`${API_DOMAIN}/ciatab`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: 'Bearer ' + token,
    //   },
    // });
    // const responseData = await response.json();
    // if (!response.ok) {
    //   const error = new Error(
    //     responseData.message || 'Error recuperando informacion.'
    //   );
    //   throw error;
    // }
    // const companias = [];
    // for (const key in responseData['ciatab']) {
    //   /*//console.log(key + '');
    //   //console.log(responseData['ciatab'][0].ciacod);*/
    //   const ciatab = {
    //     ciacod: responseData['ciatab'][key].ciacod,
    //     cianombre: responseData['ciatab'][key].cianombre,
    //     cianit: responseData['ciatab'][key].cianit,
    //     ciadir: responseData['ciatab'][key].ciadir,
    //   };
    //   //console.log('mensaje de prueba');
    //   companias.push(ciatab);
    //   ////console.log(companias);
    // }
    // //console.log('lista de valores companias');
    // console.log(companias);
    // context.commit('setListvalues', companias);
    // context.commit('setFetchTimestamp');
  },
  /******************************************************************
   *************RECUPERACION DE LISTA DE VALORES MENSAJEROS***********
   *******************************************************************
   */
  async loadListValuesMensajeros(context) {
    ////console.log(
    //  'Inicio de Metodo de Recuperacion de LISTA DE VALORES COMPANIAS.'
    //);
    const token = context.rootGetters.token;
    let responseData;

    ////console.log('mensajeros class actions');
    ////console.log(token);
    for (let retry = 0; retry < 6; retry++) {
      const response = await fetch(
        `${API_DOMAIN}/api/mosi/listado/Mensajeros`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );
      if (response.ok) {
        responseData = await response.json();
        if (responseData.mensajeros) {
          break;
        }
      }
      if (retry < 5) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Esperar 1 segundo antes de volver a intentar
      } else {
        /*const error = new Error(
          responseData?.message || 'Error recuperando información.'
        );
        throw error;*/
      }
    }

    /*const companias = responseData.ciatab.map((item) => ({
      ciacod: item.ciacod,
      cianombre: item.cianombre,
      cianit: item.cianit,
      ciadir: item.ciadir,
    }));-*/

    const mensajeros = responseData.mensajeros.map((item) => ({
      menscia: item.menscia,
      menscod: item.menscod,
      mensnom: item.mensnom,
      mensuser: item.mensuser,
      menspass: item.menspass,
    }));

    //console.log('lista de valores mensajeros');
    console.log(mensajeros);
    context.commit('setListvaluesMensajeros', mensajeros);
    context.commit('setFetchTimestamp');
  },
  /********************************************************************
   *************RECUPERACION DE LISTA DE VALORES PRIORIDADES***********
   ********************************************************************
   */
  async loadListValuesPrioridades(context) {
    const token = context.rootGetters.token;
    let responseData;

    for (let retry = 0; retry < 6; retry++) {
      const response = await fetch(
        `${API_DOMAIN}/api/mosi/listado/Prioridades`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      );

      if (response.ok) {
        responseData = await response.json();
        if (responseData.ciatab) {
          break;
        }
      }

      if (retry < 5) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Esperar 1 segundo antes de volver a intentar
      }
    }

    const prioridades = responseData.prioridades.map((item) => ({
      pricod: item.pricod,
      pridesc: item.pridesc,
    }));

    context.commit('setListvaluesPrioridades', prioridades);
    context.commit('setFetchTimestamp');
  },
  /****************************************************************************
   *************RECUPERACION DE TAZA DE CAMBIO DEL BANCO DE GUATEMALA***********
   *****************************************************************************
   */
  async tazaCambio(context) {
    ////console.log(
    //  'Inicio de Metodo de Recuperacion de LISTA DE VALORES COMPANIAS.'
    //);

    //console.log(token);
    ////console.log('Muestra de token desde otra pantalla');
    ////console.log(token);
    const response = await fetch(`${API_DOMAIN}/tipoCambio`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const responseData = await response.json();
    ////console.log('Respuesta de lista de valores sin procesar.');
    //console.log(responseData);
    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const tazascambio = [];

    const tazacambio = {
      fecha: responseData.fecha,
      referencia: responseData.referencia,
    };
    tazascambio.push(tazacambio);

    context.commit('setTazaCambio', tazascambio);
  },

  async ContadoresEnvios(context) {
    const token = context.rootGetters.token;
    const response = await fetch(
      `${API_DOMAIN}/api/Dashboard/ContadoresEnvios`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      }
    );
    console.log(response);
    const cnt = [];
    const responseData = await response.json();

    if (!response.ok) {
      const contadores = {
        registrosDiaAnterior: 0,
        registrosHoy: 0,
        totalRegistros: 0,
      };
      cnt.push(contadores);
    } else {
      const contadores = {
        registrosDiaAnterior: responseData.registros_dia_anterior,
        registrosHoy: responseData.registros_hoy,
        totalRegistros: responseData.total_registro,
      };

      cnt.push(contadores);
    }

    context.commit('setContadores', cnt);
    return cnt;
  },

  async loadListValuesCostos(context) {
    const token = context.rootGetters.token;
    let responseData;

    for (let retry = 0; retry < 1; retry++) {
      const response = await fetch(`${API_DOMAIN}/api/Docmt/Costos/0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      });
      console.log(response);
      if (response.ok) {
        responseData = await response.json();
        if (responseData.ciatab) {
          break;
        }
      }

      if (retry < 5) {
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Esperar 1 segundo antes de volver a intentar
      }
    }

    const costos = responseData.response.map((item) => ({
      cod_mes: item.cod_mes,
      mes: item.mes,
      ano: item.ano,
      ganancia: item.ganancia,
      costo: item.costo,
    }));

    //console.log('lista de valores mensajeros');
    console.log(costos);
    context.commit('setListvaluesCostos', costos);
    context.commit('setFetchTimestamp');
  },
};
