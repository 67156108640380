import { createRouter, createWebHistory } from 'vue-router';

//import CusNewList from './pages/cusnew/CusNewList.vue';
import CusnewInsert from './pages/cusnew/CusnewInsert.vue';
import UserAuth from './pages/auth/UserAuth.vue';
import NotFound from './pages/NotFound.vue';
import Deliveries from './pages/deliveries/DeliveriesList.vue';
import DeliveriesnewForm from './pages/deliveries/DeliveriesInsert.vue';
import DeliveriesUpdate from './pages/deliveries/DeliveriesUpdate.vue';
import Billing from './pages/billing/BillingList.vue';
import BillingUpdate from './pages/billing/BillingUpdate.vue';
import BillingInsert from './pages/billing/BillingInsert.vue';
import Costs from './pages/deliveries/queries/CostsList.vue';
import BillingHis from './pages/billing/BillingHisList.vue';
import ConsDeliveries from './pages/deliveries/queries/DeliveriesList.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/Home',
      name: 'dashboard',
      exact: true,
      component: () => import('./components/Dashboard.vue'),
      meta: {
        breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }],
      },
    },
    { path: '/', redirect: '/auth' },
    {
      path: '/Deliveries',
      component: Deliveries,
    },
    {
      path: '/Deliveries/:delcia/:deldoc/:delnit/:delenv/',
      component: DeliveriesUpdate,
      props: true,
      children: [{ path: 'Deliveries', component: DeliveriesUpdate }],
    },
    { path: '/RegistroNuevo', component: CusnewInsert },
    { path: '/auth', component: UserAuth },
    { path: '/:catchAll(.*)', redirect: '/notfound' },
    { path: '/notfound', component: NotFound },
    { path: '/newDeliveries', component: DeliveriesnewForm },
    {
      path: '/Billing',
      component: Billing,
    },
    {
      path: '/History/Billing',
      component: BillingHis,
    },
    {
      path: '/Consultas/Costos',
      component: Costs,
    },
    { path: '/NewRegister/Billings', component: BillingInsert },
    {
      path: '/Billing/update/:docmdoc',
      component: BillingUpdate,
      props: true,
      children: [{ path: 'Billing', component: BillingUpdate }],
    },
    {
      path: '/Consultas/Deliveries',
      component: ConsDeliveries,
    },
  ],
});

export default router;
