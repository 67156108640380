let timer;
import API_DOMAIN from '../../../config';

export default {
  async login(context, payload) {
    //console..log('LOGIN METHOD');
    //console..log('---------------------------------------');
    /*console.log(
      JSON.stringify({
        usuario: payload.email,
        password: payload.password,
      })
    );*/
    const response = await fetch(`${API_DOMAIN}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usuario: payload.email,
        password: payload.password,
      }),
    });
    //console.log(response);

    const responseData = await response.json();

    if (!response.ok) {
      console.log(responseData);
      const error = new Error(responseData.mensaje || 'Error iniciando sesion');
      console.log(error);
      throw error;
    } else {
      //console..log(responseData);
      //console..log(response);
    }
    console.log(responseData.expireIn);
    const expiresIn = +responseData.expireIn * 1000;

    const expirationDate = new Date().getTime() + expiresIn;
    console.log(expirationDate);

    localStorage.setItem('token', responseData.idToken);
    localStorage.setItem('userId', payload.email);
    localStorage.setItem('expireIn', expirationDate);

    context.commit('setUser', {
      token: responseData.idToken,
      userId: payload.email,
    });
  },
  async signup(context, payload) {
    const response = await fetch('https://127.0.0.1:9016/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        usuario: payload.email,
        passsword: payload.password,
      }),
    });

    const responseData = await response.json();
    //console..log(response);
    if (!response.ok) {
      //console..log(responseData);
      const error = new Error(responseData.message || 'Error iniciando sesion');
      throw error;
    }
    ////console..log(responseData);

    context.commit('setUser', {
      token: responseData.idToken,
      userId: responseData.localId,
    });
  },
  logout(context) {
    /*localStorage.removeItem('token');
    localStorage.removeItem('userId');*/

    context.commit('setUser', {
      token: null,
      userId: null,
    });
  },
  async activateCusNewDir(context) {
    const token = context.rootGetters.token;

    const response = await fetch('https://127.0.0.1:9016/activate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const responseData = await response.json();
    //console..log(responseData);
    if (!response.ok) {
      console.log(response);
      const error = new Error(responseData.message || 'Error iniciando sesion');
      throw error;
    }
  },
  async activateCusNew(context) {
    const token = context.rootGetters.token;

    const response = await fetch('https://127.0.0.1:9016/activate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    });

    const responseData = await response.json();
    //console..log(responseData);
    if (!response.ok) {
      ////console..log(responseData);
      const error = new Error(responseData.message || 'Error iniciando sesion');
      throw error;
    }
  },
  tryLogin(context) {
    /*localStorage.setItem('token', responseData.idToken);
    localStorage.setItem('userId', payload.email);
    localStorage.setItem('expireIn', payload.expireIn);*/
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const tokenExpiration = localStorage.getItem('expireIn');

    const expiresIn = +tokenExpiration - new Date().getTime();
    console.log('token 1: ' + expiresIn);
    if (expiresIn < 0) {
      return;
    }
    console.log('token 2: ' + expiresIn);
    timer = setTimeout(function () {
      context.dispatch('autoLogout');
    }, expiresIn);

    console.log(timer);

    if (token && userId) {
      context.commit('setUser', {
        token: token,
        userId: userId,
      });
    }
  },
  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');
  },
  async loadMenybyUser(context) {
    console.log("Recuperacion menu por usuario logueado");
  
    const token = context.rootGetters.token;
    const response = await fetch(
      `${API_DOMAIN}/api/User/Menu`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //cache: 'no-store',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    console.log(response);
    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Error recuperando informacion.'
      );
      throw error;
    }
    const responseData = await response.json();
    console.log(responseData);
    return responseData
  }

  
};
