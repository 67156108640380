<template>
  <Toast />
  <DataTable
    ref="dv"
    responsiveLayout="scroll"
    id="facturas"
    class="p-datatable-gridlines"
    :value="billings"
    :rowHover="true"
    dataKey="docmdoc"
    :paginator="true"
    :loading="loading1"
    :rows="25"
    :filters="filters"
    showGridlines
    v-model:selection="selectedProducts3"
    v-model:expandedRows="expandedRows"
    @rowExpand="onRowExpand"
    @rowCollapse="onRowCollapse"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[25, 50, 75, 100, 125]"
    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} facturas"
  >
    <template #header>
      <div
        class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
      ><div class="p-formgroup-inline">
        <span class="p-input-icon-left">
          <!-- <div class="p-inputgroup">
                  <InputText placeholder="Keyword" /><Button label="Search" />
                </div> -->

          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            placeholder="Buscar..."
            size="75"
          /><Button
            type="button"
            icon="pi pi-filter-slash"
            class="p-button-outlined"
            @click="initFilters()"
          />
          
        
          
        </span>
        </div>
        <div class="p-formgroup-inline">
          <Calendar v-model="date" view="month" dateFormat="MM/yy" @date-select="handleMonthChange"/> </div>
 
      </div>
    </template>
    <template #empty><b>No se encontró ningúna factura ingresada.</b></template>
    <template #loading> Cargando todas las facturas... </template>
    <Column :expander="true" :headerStyle="{ width: '3rem' }" />
    

    <Column
      field="docmdoc"
      width="5%"
      header="No. Factura"
      :sortable="true"
      style="min-width: 1rem"
    ></Column>
    <Column
      field="docmnom"
      header="Nombre Cliente"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="docmnit"
      header="NIT"
      :sortable="true"
      style="min-width: 3rem"
    ></Column>
    <Column
      field="docmfecped"
      header="Fecha de Creacion"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>

    <Column
      field="docmfecdoc"
      header="Fecha Factura"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>

    <Column
      field="docmtot"
      header="Total Factura (Q.)"
      :sortable="true"
      style="text-align: right"
    ></Column>
    <Column
      field="docmuuid"
      header="Autorizacion FEL"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>

    <Column :exportable="false" style="min-width: 8rem">
      <template #body="slotProps">
       

        <Button
          icon="pi pi-file-pdf"
          class="p-button-rounded p-button-danger p-mr-2"
          @click="generatedFileToSave(slotProps.data)"
        />
        <!-- @click="enviaCorreo(slotProps.data)"-->
        <Button
          icon="pi pi-envelope"
          class="p-button-rounded p-button-info p-mr-2"
          @click="generatedFileToSend(slotProps.data)"
        />
      </template>
    </Column>
    <template #expansion="slotProps">
      <div class="p-3">
        <DataTable :value="slotProps.data.items">
          <template #header>
            <div class="flex flex-wrap justify-content-end gap-3">
              <h5>Detalle del Documento:  {{ slotProps.data.docmdoc }}</h5>
              <h5>nombre de:  {{ slotProps.data.docmnom }} </h5>
              <h5>Correo Electronico: {{ slotProps.data.docmtentotr }}
              </h5>
            </div>
          </template>
          <Column field="docdprd" header="Codigo" sortable style="text-align: right"></Column>
          <Column field="docdesc" header="Producto" sortable></Column>
          <Column field="docdunivta" header="Cantidad" sortable style="text-align: right"></Column>
          <Column field="docdprecaj1" header="Precio" sortable style="text-align: right"> </Column>
          <Column field="docdvallin" header="Valor" sortable style="text-align: right"> </Column>
        </DataTable>
      </div>
    </template>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  
  data() {
    return {
      date: null,
      periodo:null,
      esLocale: null,
      es: {
        firstDayOfWeek: 1,
        dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
        dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
        dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
        monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
        today: 'Hoy',
        clear: 'Borrar',
        dateFormat: 'mm/yy', // Asegúrate de que el formato de la fecha sea correcto
        weekHeader: 'Sm'
      },
      selectedCity:null,
      Meses: [
			{name: 'enero', code: 1},
			{name: 'febrero', code: 2},
			{name: 'marzo', code: 3},
			{name: 'abril', code: 4},
			{name: 'mayo', code: 5},
      {name: 'junio', code: 6},
			{name: 'julio', code: 7},
			{name: 'agosto', code: 8},
			{name: 'septiembre', code: 9},
			{name: 'octubre', code: 10},
      {name: 'noviembre', code: 11},
			{name: 'diciembre', code: 12}
		],
      detailxDocSelected: [],
      products: null,
      expandedRows: [],

      checked: false,
      fecha_docs: null,
      hora_docs: null,
      delcia: { val: null, isValida: true },
      displayBasic: false,
      items: [
        {
          label: 'Generacion PDF',
          icon: 'pi pi-file-pdf',
          command: () => {
            this.openBasic();
          },
        },
        {
          label: 'Generacion TXT',
          icon: 'pi pi-file',
          command: () => {
            this.openBasicTxt();
          },
        },
      ],
      total_facturas: 0,
      total: 0,
      billings: null,
      filters: null,
      certifica: false,
      rowSelection: null,
      rowData: null,
      selectedProducts3: null,
      displayFel: false,
      ciatab: null,
      filteredCiatab: null,
      fechadel: null,
      fechaal: null,
      loading1: true,
      nombresMeses : [
  "enero", "febrero", "marzo", "abril", "mayo", "junio",
  "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
],
    };
  },
  created() { 
    
    this.periodo = ((new Date().getMonth()+1)-1)+""+new Date().getFullYear().toString().substring(2, 4)
    console.log(this.periodo)
    const mesActual = new Date().getMonth()-1;
    this.selectedCity = this.Meses[mesActual];
    this.date = this.selectedCity.name+'/'+new Date().getFullYear()
    console.log(this.date)
    console.log('selectedCity:', this.selectedCity);
    this.initFilters();
    this.loadListValuesCiatab(true);
    this.loadBillings(true, 1);
   

    // Asignar el mes actual a selectedCity
    
    //this.loadBillings(true, 1);
  },
  computed: {
    filtraBilings() {
      const billings = this.$store.getters['billings/billings']['billings'];
      
      // for (const key in billings) {
      //   if (
      //     billings[key].docmuuid === null ||
      //     billings[key].docmuuid === 'null'
      //   ) {
      //     console.log('documento sin certificar');
      //   } else if (billings[key].docmuuid.includes(': [')) {
      //     this.$toast.add({
      //       severity: 'error',
      //       summary: 'Error Certificando Documento ' + billings[key].docmdoc,
      //       detail:
      //         'El documento ' +
      //         billings[key].docmdoc +
      //         ' tuvo el siguiente error ' +
      //         billings[key].docmuuid,
      //       //group: 'br',
      //       life: 4500,
      //     });
      //   }
      // }
      this.ordenarAsc(billings, 'docmfecped');
      this.ordenarAsc(billings, 'docmdoc');

      return billings;
    },
    listValuesCiatab() {
      try {
        this.loadListValuesCiatab(true);
        const ciatab =
          this.$store.getters['listvalues/listvalues']['listvalues'];
        console.log(ciatab);
        return ciatab.filter((ciatab) => {
          if (ciatab.ciacod != 0) {
            return true;
          }
          if (ciatab.ciacod != 0) {
            return true;
          }
          console.log(ciatab);
          return true;
        });
      } catch (error) {
        console.log(error.messages);
        return true;
      }
    },
  },
  
  methods: {
    handleMonthChange($event) {
      this.periodo = $event.getMonth()+1+""+$event.getFullYear().toString().substring(2, 4)
      console.log('Mes cambiado:',this.periodo );
      this.loadBillings(true,1)
    },
    selectMonth() {
      
    
    /*const cityName = this.selectedCity.name;
    const cityCode = this.selectedCity.code;*/
    this.loadBillings(true,1)
    
  
     
  
    },
    onRowExpand(event) {
      console.log(event);
      //Si se desea que cuando se expanda el detalle de un documento se haga algo, se debe agrega aca
      console.log(false, event.data);
      this.loadDetailxDoc(false, event.data);
    },
    onRowCollapse(event) {
      console.log(event);
      //Si se desea que cuando se colapse el detalle de un documento se haga algo, se debe agrega aca
    },
    async enviaCorreo(product, base) {
      console.log(product);
      if (!product.docmtentotr.includes('@')) {
        this.$toast.add({
          severity: 'error',
          summary: 'Envio de Correo',
          detail:
            'NO se puede enviar este documento ' +
            product.docmdoc +
            ' debido a que no se cuenta con un correo registrado',
          //group: 'br',
          life: 4500,
        });
      } else {
        if (!product.docmuuid || product.docmentdir == product.docmuuid) {
          this.$toast.add({
            severity: 'error',
            summary: 'Envio de Correo',
            detail:
              'No se puede enviar el correo de este documento debido a que el documento no se encuentra certificado',
            //group: 'br',
            life: 5000,
          });
        } else {
          console.log(base);
          const infodatamail = {
            asunto: 'Documento Electronico Lupnova S,A.',
            nit: product.docmnit,
            quien_envia_nombre: 'Lupnova Joyería',
            quien_envia_mail: 'info@lupnova.com',
            quien_recibe:
              product.docmentdpper === null
                ? product.docmnom
                : product.docmentdpper,
            quien_recibe_mail: product.docmtentotr,
            nombre_archivo: product.docmuuid + '.pdf',
            tipo: '',
            autorizacion: product.docmuuid,
            serie: product.docmfelser,
            numero: product.docmfeldoc,
            establecimiento: 'Lupnova Joyería',
            fecha: product.docmfelfec,
            monto: product.docmtot,
            interno: product.docmcia + '-' + product.docmdoc,
            adjunto: base.replace(
              'data:application/pdf;filename=generated.pdf;base64,',
              ''
            ),
          };
          const result = await this.$store
            .dispatch('billings/sendMail', infodatamail)
            .then((result) => {
              this.$toast.add({
                severity: result.status === 0 ? 'error' : 'info',
                summary: 'Envio de Correo',
                detail: result.message,
                //group: 'br',
                life: 5000,
              });
            })
            .catch((error) => {
              console.error('¡Error! enviando correo');
              this.$toast.add({
                severity: 'error',
                summary: 'No fue enviado el correo electronico',
                detail: error,
                //group: 'br',
                life: 5000,
              });
            });
          console.log(result);
        }
      }
    },
    GenFileEmail(product) {
      //
      if (product.docmuuid == null || product.docmuuid === null) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Envio de Correo',
          detail:
            'No se puede enviar el correo del documento seleccionado debido a que este no se encuentra certificado!',
          //group: 'br',
          life: 4500,
        });
      } else {
        if (!product.docmtentotr.includes('@')) {
          this.$toast.add({
            severity: 'error',
            summary: 'Envio de Correo',
            detail:
              'NO se puede enviar este documento ' +
              product.docmdoc +
              ' debido a que no se cuenta con un correo registrado',
            //group: 'br',
            life: 4500,
          });
        } else {
          //Como si tiene un correo, iremos a recuperar el archivo PDF

          this.recoverFileforEmail(product, false);
        }
      }
    },
    registroNuevo() {
      const redirectUrl =
        '/' + (this.$route.query.redirect || 'NewRegister/Billings');
      this.$router.replace(redirectUrl);
    },
    generatedFileToSave(selectedDoc) {
      var doc = this.exportPDFv2(selectedDoc);

      doc.save(
        selectedDoc.docmuuid == null
          ? selectedDoc.docmdoc
          : selectedDoc.docmuuid + '.pdf'
      );
    },
    generatedFileToSend(selectedDoc) {
      var doc = this.exportPDFv2(selectedDoc);
      this.enviaCorreo(
        selectedDoc,
        doc
          .output('datauristring')
          .replace('data:application/pdf;filename=generated.pdf;base64,', '')
      );
    },
    exportPDFv2(selectedDoc) {
      //console.log(selectedDoc);
      var head = [
        [
          'No.',
          'Descripcion',
          'Cantidad',
          'Precio Unitario',
          'Sub Total',
          'Impuestos (IVA)',
        ],
      ];
      var body = [];
      let linea = 0;
      let body2 = [];
      let nombre,
        direccion,
        nit,
        /*uuid,
        felser,
        feldoc,
        felfec,*/
        compania,
        ciadir,
        cianit,
        total;

      nombre =
        selectedDoc.docmentdpper == null
          ? selectedDoc.docmnom
          : selectedDoc.docmentdpper;
      //console.log(nombre);
      direccion = selectedDoc.docmdir;
      //console.log(direccion);
      nit = selectedDoc.docmnit;
      //console.log(nit);
      /*uuid = selectedDoc.docmuuid;
      //console.log(uuid);
      felser = selectedDoc.docmfelser;
      //console.log(felser);
      feldoc = String(selectedDoc.docmfeldoc);
      //console.log(feldoc);
      felfec = selectedDoc.docmfelfec;*/
      //console.log(felfec);
      total = Number.parseFloat(selectedDoc.docmtot).toFixed(2);
      //console.log(total);
      for (const key1 in this.$store.getters['listvalues/listvalues'][
        'listvalues'
      ]) {
        if (
          this.$store.getters['listvalues/listvalues']['listvalues'][key1]
            .ciacod === selectedDoc.docmcia
        ) {
          compania =
            this.$store.getters['listvalues/listvalues']['listvalues'][key1]
              .cianombre;
          ciadir =
            this.$store.getters['listvalues/listvalues']['listvalues'][key1]
              .ciadir;
          cianit =
            this.$store.getters['listvalues/listvalues']['listvalues'][key1]
              .cianit;
        }
      }
      for (const key2 in selectedDoc.items) {
        linea++;
        body2 = [
          linea,
          selectedDoc.items[key2].docdesc,
          selectedDoc.items[key2].docdunivta,

          Number.parseFloat(selectedDoc.items[key2].docdprcuni).toFixed(2),
          Number.parseFloat(
            Number.parseFloat(selectedDoc.items[key2].docdprcuni).toFixed(2) *
              Number.parseInt(selectedDoc.items[key2].docdunivta)
          ).toFixed(2),
          Number.parseFloat(selectedDoc.items[key2].docdiva).toFixed(2),
        ];

        body.push(body2);
      }
      let footer = new Image();
      footer.src = '../assets/layout/images/lupnovareports.png';

      var doc = new jsPDF('p', 'in', [8.5, 11]);
      doc.setFontSize(9);
      doc.addImage(footer, 'png', 0.2, 0.1, 1.3, 1.2);
      //var width = doc.internal.pageSize.getWidth();
      //var splitTitle = doc.splitTextToSize(reportTitle, 180);
      //doc.text(15, 20, splitTitle);
      doc.text(
        'Factura de Pequeño Contribuyente',
        doc.internal.pageSize.getWidth() / 2,
        0.3,
        {
          align: 'center',
        }
      );
      /*console.log(
        selectedDoc.docmuuid == null
          ? 'Documento por Contingencia'
          : 'Documento Tributario Electronico'
      );*/
      doc.text(
        selectedDoc.docmuuid == null
          ? 'Documento por Contingencia'
          : 'Documento Tributario Electronico',
        doc.internal.pageSize.getWidth() / 2,
        0.5,
        { align: 'center' }
      );
      //
      //console.log(compania);
      doc.text(compania, doc.internal.pageSize.getWidth() / 2, 0.7, {
        align: 'center',
      });
      //console.log('NIT EMISOR: ' + cianit);
      doc.text(
        'NIT EMISOR: ' + cianit,
        doc.internal.pageSize.getWidth() / 2,
        0.9,
        {
          align: 'center',
        }
      );
      doc.text('LUPNOVA JOYERIA', doc.internal.pageSize.getWidth() / 2, 1.1, {
        align: 'center',
      });
      //console.log(ciadir);
      doc.text(ciadir, doc.internal.pageSize.getWidth() / 2, 1.3, {
        align: 'center',
      });
      doc.text('***No genera derecho a crédito fiscal', 0.17, 10.5, {
        align: 'left',
      });
      doc.text(
        'DATOS DEL CERTIFICADOR',
        doc.internal.pageSize.getWidth() / 2,
        10.7,
        {
          align: 'center',
        }
      );
      //console.log(ciadir);
      doc.text(
        'COFIDI SOCIEDAD ANONIMA     NIT: 62469045',
        doc.internal.pageSize.getWidth() / 2,
        10.9,
        {
          align: 'center',
        }
      );
      /*doc.text(ciadir, doc.internal.pageSize.getWidth() / 2, 10.8, {
          align: 'center',
        });*/
      doc.text('Nombre:', 0.17, 1.7);
      doc.text(nombre, 0.7, 1.7);
      //console.log(nombre);
      //doc.text('Direccion:', 0.17, 1.9);
      var splitDireccion = doc.splitTextToSize(
        'Direccion: ' + direccion.substring(0, 156),
        5
      );
      //console.log(selectedDoc);

      doc.text(splitDireccion, 0.17, 1.9);
      doc.text('NIT/CUI/PASAPORTE:', 0.17, 2.2);
      doc.text(nit, 1.5, 2.2);
      console.log(nit);
      doc.text('Autorizacion:', 5.5, 1.5);
      doc.text(
        selectedDoc.docmuuid == null ? '' : selectedDoc.docmuuid,
        5.5,
        1.7
      );
      //console.log(selectedDoc.docmuuid == null ? '' : selectedDoc.docmuuid);
      doc.text('Serie:', 5.5, 1.9);
      doc.text(
        selectedDoc.docmfelser == null ? '' : selectedDoc.docmfelser,
        5.9,
        1.9
      );
      //console.log(selectedDoc.docmfelser == null ? '' : selectedDoc.docmfelser);
      doc.text('Número:', 5.5, 2.1);
      doc.text(
        String(selectedDoc.docmfeldoc) == 'null'
          ? ''
          : String(selectedDoc.docmfeldoc),
        6,
        2.1
      );
      /*console.log(
        String(selectedDoc.docmfeldoc) == 'null'
          ? ''
          : String(selectedDoc.docmfeldoc)
      );*/
      doc.text('Fecha:', 5.5, 2.3);
      doc.text(
        selectedDoc.docmfelfec == null ? '' : selectedDoc.docmfelfec,
        6,
        2.3
      );
      //console.log(selectedDoc.docmfelfec == null ? '' : selectedDoc.docmfelfec);
      doc.text('Moneda: GTQ', 5.5, 2.6);
      doc.autoTable({
        head: head,
        body: body,
        foot: [['', '', '', 'Total', total]],
        startY: 3,
        margin: [1, 1, 1, 0.2],
        headStyles: { fillColor: [0, 0, 0, 255] },
        didParseCell: (data) => {
          if (data.cell && data.cell.section === 'foot') {
            data.cell.styles.halign = 'right';
          }
        },
        //idealsa
        columnStyles: {
          0: { cellWidth: 0.37, halign: 'left' },
          1: { cellWidth: 4.5, halign: 'left' },
          2: { cellWidth: 0.8, halign: 'right' },
          3: { cellWidth: 0.8, halign: 'right' },
          4: { cellWidth: 0.8, halign: 'right' },
          5: { cellWidth: 0.9, halign: 'right' },
        },
        footStyles: {
          fillColor: [0, 0, 0, 255],
        },
      });
      return doc;
    },
    exportPDFFactura(product) {
      const infodata = {
        docmcia: product.docmcia,
        docmdoc: product.docmdoc,
      };
      this.loadDetail(true, infodata);
      setTimeout(() => {
        console.log(this.$store.getters['billings/billings']['docdt']);
        var head = [
          [
            'No.',
            'Descripcion',
            'Cantidad',
            'Precio Unitario',
            'Sub Total',
            'Impuestos (IVA)',
          ],
        ];
        var body = [];
        let linea = 0;
        let body2 = [];
        let nombre,
          direccion,
          nit,
          uuid,
          felser,
          feldoc,
          felfec,
          compania,
          ciadir,
          cianit,
          total;

        const documentos = this.$store.getters['billings/billings']['billings'];
        for (const key in documentos) {
          if (documentos[key].docmdoc === product.docmdoc) {
            console.log(documentos[key]);
            /*
            uuid == null
            ? 'Documento por Contingencia'
            : 'Documento Tributario Electronico',
          doc.internal.pageSize.getWidth() / 2,
          0.5,
          { align: 'center' }*/
            nombre =
              documentos[key].docmentdpper == null
                ? documentos[key].docmnom
                : documentos[key].docmentdpper;
            console.log(nombre);
            direccion = documentos[key].docmdir;
            console.log(direccion);
            nit = documentos[key].docmnit;
            console.log(nit);
            uuid = documentos[key].docmuuid;
            console.log(uuid);

            felser = documentos[key].docmfelser;
            console.log(felser);

            feldoc = String(documentos[key].docmfeldoc);
            console.log(feldoc);

            felfec = documentos[key].docmfelfec;
            console.log(felfec);

            total = Number.parseFloat(documentos[key].docmtot).toFixed(2);
            console.log(total);

            for (const key1 in this.$store.getters['listvalues/listvalues'][
              'listvalues'
            ]) {
              if (
                this.$store.getters['listvalues/listvalues']['listvalues'][key1]
                  .ciacod === product.docmcia
              ) {
                compania =
                  this.$store.getters['listvalues/listvalues']['listvalues'][
                    key1
                  ].cianombre;
                ciadir =
                  this.$store.getters['listvalues/listvalues']['listvalues'][
                    key1
                  ].ciadir;
                cianit =
                  this.$store.getters['listvalues/listvalues']['listvalues'][
                    key1
                  ].cianit;
              }
            }
            for (const key2 in this.$store.getters['billings/billings'][
              'docdt'
            ]) {
              linea++;
              body2 = [
                linea,
                this.$store.getters['billings/billings']['docdt'][key2].docdesc,
                this.$store.getters['billings/billings']['docdt'][key2]
                  .docdunivta,

                Number.parseFloat(
                  this.$store.getters['billings/billings']['docdt'][key2]
                    .docdprcuni
                ).toFixed(2),
                Number.parseFloat(
                  Number.parseFloat(
                    this.$store.getters['billings/billings']['docdt'][key2]
                      .docdprcuni
                  ).toFixed(2) *
                    Number.parseInt(
                      this.$store.getters['billings/billings']['docdt'][key2]
                        .docdunivta
                    )
                ).toFixed(2),
                Number.parseFloat(
                  this.$store.getters['billings/billings']['docdt'][key2]
                    .docdiva
                ).toFixed(2),
              ];

              body.push(body2);
            }
          }
        }

        let footer = new Image();
        footer.src = 'assets/layout/images/lupnovareports.png';

        var doc = new jsPDF('p', 'in', [8.5, 11]);
        doc.setFontSize(9);
        doc.addImage(footer, 'png', 0.2, 0.1, 1.3, 1.2);
        //var width = doc.internal.pageSize.getWidth();
        //var splitTitle = doc.splitTextToSize(reportTitle, 180);
        //doc.text(15, 20, splitTitle);
        doc.text(
          'Factura de Pequeño Contribuyente',
          doc.internal.pageSize.getWidth() / 2,
          0.3,
          {
            align: 'center',
          }
        );
        console.log(
          uuid == null
            ? 'Documento por Contingencia'
            : 'Documento Tributario Electronico'
        );
        doc.text(
          uuid == null
            ? 'Documento por Contingencia'
            : 'Documento Tributario Electronico',
          doc.internal.pageSize.getWidth() / 2,
          0.5,
          { align: 'center' }
        );
        //
        console.log(compania);
        doc.text(compania, doc.internal.pageSize.getWidth() / 2, 0.7, {
          align: 'center',
        });
        console.log('NIT EMISOR: ' + cianit);
        doc.text(
          'NIT EMISOR: ' + cianit,
          doc.internal.pageSize.getWidth() / 2,
          0.9,
          {
            align: 'center',
          }
        );
        doc.text('LUPNOVA JOYERIA', doc.internal.pageSize.getWidth() / 2, 1.1, {
          align: 'center',
        });
        console.log(ciadir);
        doc.text(ciadir, doc.internal.pageSize.getWidth() / 2, 1.3, {
          align: 'center',
        });

        doc.text('*No genera derecho a crédito fiscal', 0.17, 10.5, {
          align: 'left',
        });
        doc.text(
          'DATOS DEL CERTIFICADOR',
          doc.internal.pageSize.getWidth() / 2,
          10.7,
          {
            align: 'center',
          }
        );
        console.log(ciadir);
        doc.text(
          'COFIDI SOCIEDAD ANONIMA     NIT: 62469045',
          doc.internal.pageSize.getWidth() / 2,
          10.9,
          {
            align: 'center',
          }
        );
        /*doc.text(ciadir, doc.internal.pageSize.getWidth() / 2, 10.8, {
          align: 'center',
        });*/
        doc.text('Nombre:', 0.17, 1.7);
        console.log(nombre);
        doc.text(nombre, 0.7, 1.7);
        //doc.text('Direccion:', 0.17, 1.9);
        var splitDireccion = doc.splitTextToSize(
          'Direccion: ' + direccion.substring(0, 156),
          5
        );
        doc.text(splitDireccion, 0.17, 1.9);

        console.log(splitDireccion);
        doc.text('NIT/CUI/PASAPORTE:', 0.17, 2.2);
        doc.text(nit, 1.5, 2.2);
        console.log(nit);
        doc.text('Autorizacion:', 5.5, 1.5);
        //data.bultos == null ? 'null' : data.bultos
        console.log(uuid == null ? '' : uuid);
        doc.text(uuid == null ? '' : uuid, 5.5, 1.7);
        doc.text('Serie:', 5.5, 1.9);
        console.log(felser == null ? '' : felser);

        doc.text(felser == null ? '' : felser, 5.9, 1.9);
        doc.text('Número:', 5.5, 2.1);
        console.log(feldoc == null ? '' : feldoc);

        doc.text(feldoc == 'null' ? '' : feldoc, 6, 2.1);
        doc.text('Fecha:', 5.5, 2.3);
        console.log(felfec == null ? '' : felfec);

        doc.text(felfec == 'None' ? '' : felfec, 6, 2.3);
        doc.text('Moneda: GTQ', 5.5, 2.6);

        //doc.addImage(footer, 'PNG', 110, 700, 384.1);
        //doc.autoTable({});
        doc.autoTable({
          head: head,
          body: body,
          foot: [['', '', '', 'Total', total]],
          startY: 3,
          margin: [1, 1, 1, 0.2],
          headStyles: { fillColor: [0, 0, 0, 255] },
          didParseCell: (data) => {
            if (data.cell && data.cell.section === 'foot') {
              data.cell.styles.halign = 'right';
            }
          },
          //idealsa
          columnStyles: {
            0: { cellWidth: 0.37, halign: 'left' },
            1: { cellWidth: 4.5, halign: 'left' },
            2: { cellWidth: 0.8, halign: 'right' },
            3: { cellWidth: 0.8, halign: 'right' },
            4: { cellWidth: 0.8, halign: 'right' },
            5: { cellWidth: 0.9, halign: 'right' },
          },
          footStyles: {
            fillColor: [0, 0, 0, 255],
          },
        });

        //doc.output('dataurlnewwindow');

        doc.save(uuid == null ? product.docmdoc : uuid + '.pdf');
      }, 2000);
    },
    traslada() {
      this.loading1 = true;
      this.trasladoDocumentos(true);
      this.displayBasic = false;
    },
    async loadDetailxDoc(refresh = false, formData) {
      this.detallebill = null;
      console.log('Recuperacion de detalle');
      var detalle;
      try {
        detalle = await this.$store.dispatch('billings/loadDetailBillxDoc', formData, {
          forceRefresh: refresh,
        });
        console.log(formData.docmcia)
        console.log(detalle);
        formData.items.push(detalle)
        console.log(formData)
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        console.log(error.message);
      }
    },
    async loadDetail(refresh = false, formData) {
      this.detallebill = null;

      try {
        await this.$store.dispatch('billings/loadDetailBill', formData, {
          forceRefresh: refresh,
        });
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        console.log(error.message);
      }
    },
    async trasladoDocumentos(refresh = false) {
      this.billings = null;
      const formData = {
        fechadel: this.fechadel,
        fechaal: this.fechaal,
      };
      try {
        await this.$store.dispatch('billings/trasladoDocumentos', formData, {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }
      setTimeout(() => {
        this.loadBillings(true, 1);
      }, 1100);
      return true;
    },
    openDialogTraslado() {
      this.displayBasic = true;
    },
    UpdateLink(bill) {
      const redirectUrl = this.$route.path + '/update/' + bill.docmdoc;

      this.$router.replace(redirectUrl);
    },
    closeBasic() {
      this.displayFel = false;
      this.selectedProducts3 = [];
      this.total_facturas = 0;
      this.loading1 = false;
    },
    certificaDocumentos() {
      this.loading1 = true;
      if (
        this.selectedProducts3 == null ||
        this.selectedProducts3.length == 0
      ) {
        this.$toast.add({
          severity: 'info',
          summary: 'Certificacion de Documentos',
          detail: 'No ha seleccionado ningun documento para ser certificado',
          //group: 'br',
          life: 3500,
        });
        this.loading1 = false;
      } else {
        this.displayFel = true;
        for (const key in this.selectedProducts3) {
          console.log(this.selectedProducts3[key].docmtot);
          this.total_facturas += Number(this.selectedProducts3[key].docmtot);
          console.log(this.total_facturas);
        }
        this.total = Number.parseFloat(this.total_facturas).toFixed(2);
        this.total_facturas = 0;
      }
    },
    CertificarDocumentos() {
      console.log(this.selectedProducts3);
      var fact_send_mail = this.selectedProducts3;
      console.log(fact_send_mail);
      let formData = {};
      let uuid;
      for (const org in this.billings) {
        for (const opc in this.selectedProducts3) {
          if (
            this.billings[org].docmdoc == this.selectedProducts3[opc].docmdoc
          ) {
            uuid = this.billings[org].docmuuid;
          }
        }
      }
      for (const key in this.selectedProducts3) {
        console.log(uuid);
        if (!uuid || this.selectedProducts3[key].docmentdir == uuid) {
          //delcia.val
          formData = {
            docmcia: this.delcia.val.substr(
              0,
              this.delcia.val.indexOf('-') - 1
            ), //this.selectedProducts3[key].docmcia,
            docmnit: this.selectedProducts3[key].docmnit,
            docmper: this.selectedProducts3[key].docmper,
            docmtip: this.selectedProducts3[key].docmtip,
            docmser: this.selectedProducts3[key].docmser,
            docmdoc: this.selectedProducts3[key].docmdoc,
            docmflg: this.selectedProducts3[key].docmflg,
            docmtentotr: this.selectedProducts3[key].docmtentotr,
            docmnom: this.selectedProducts3[key].docmnom,
            docmtot: this.selectedProducts3[key].docmtot,
            docmfelfec: this.selectedProducts3[key].docmfecdoc,
            docmdir: this.selectedProducts3[key].docmdir,
            docmuuid: null,
            docmfelser: null,
            docmfeldoc: 'null',
            docmentdpper: this.selectedProducts3[key].docmentdpper,

            items: this.selectedProducts3[key].items,
          };
          //Este metodo se encarga de enviar a firmar los documentos
          this.CertDocs(formData, true);
        } else {
          this.$toast.add({
            severity: 'info',
            summary: 'No se puede Certificar',
            detail:
              'El documento ' +
              this.selectedProducts3[key].docmdoc +
              ' ya se encuentra certificado!',
            //group: 'br',
            life: 3500,
          });
        }
      }
      //Hace el refresco de las factuas y asi verificar si se firmaron los documentos
      setTimeout(() => {
        this.loadBillings(true, 2);
      }, 2500);
      //Luego de refrescar se verifica si los documentos fueron certificados para enviar correos

      this.closeBasic();
    },
    async CertDocs(formData, refresh = false) {
      formData.docmcia = parseInt(formData.docmcia);

      try {
        const responseData = await this.$store.dispatch(
          'billings/certificacionDocumentos',
          formData,
          {
            forceRefresh: refresh,
          }
        );

        const datoEspecifico = responseData.Status;
        formData.docmuuid = responseData.DocmUUID;
        formData.docmentdpper = responseData.DocmENTDPPER;
        formData.docmfeldoc = responseData.DocmFELDOC;
        formData.docmfelser = responseData.DocmFELSER;

        if (datoEspecifico == 0) {
          this.$toast.add({
            severity: 'warn',
            summary: 'Documento NO Certificado',
            detail:
              'El documento ' +
              formData.docmdoc +
              ' no pudo ser certificado debido a: ' +
              responseData.DocmENTDIR,
            //group: 'br',
            life: 5500,
          });
        } else {
          //Se verifica si contiene un correo para poder recuperar el archivo PDF
          if (!formData.docmtentotr.includes('@')) {
            this.$toast.add({
              severity: 'error',
              summary: 'Envio de Correo',
              detail:
                'NO se puede enviar este documento ' +
                formData.docmdoc +
                ' debido a que no se cuenta con un correo registrado',
              //group: 'br',
              life: 4500,
            });
          } else {
            //Como si tiene un correo, iremos a recuperar el archivo PDF
            //this.recoverFileforEmail(formData, false);
            var doc = this.exportPDFv2(formData);
            this.enviaCorreo(
              formData,
              doc
                .output('datauristring')
                .replace(
                  'data:application/pdf;filename=generated.pdf;base64,',
                  ''
                )
            );
          }
          console.log(formData.docmtentotr);
        }
        //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async loadBillings(refresh = false) {
      this.isLoading = true;
      this.billings = null;

      /*try {
        await this.$store.dispatch('billings/loadBillings', cuscia, {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }*/
      try {
        const formData={
          param1:'H',
          param2:this.periodo
        }
        await this.$store.dispatch('billings/loadBillingsMaster',formData, {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
        
        this.billings = this.filtraBilings;
        this.isLoading = false;
        this.loading1 = false;
        let detail;
        try {
          for (const key in this.billings) {
            detail =  await this.$store.dispatch('billings/loadDetailBillxDoc', this.billings[key], {
            forceRefresh: refresh,
            }); //viene de la clases action.js G
            //console.log(detail)
//            this.billings[key].items.push(detail)
            this.billings[key].items = detail
            //console.log(this.billings[key])
          }
        } catch (error) {
          console.log(error)
        }
        //this.billings = this.filtraBilings;
        //console.log(this.billings);
        this.ciatab = this.listValuesCiatab;
        if (this.ciatab.length == 0) {
          this.ciatab = this.listValuesCiatab;
        }
        
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      //setTimeout(() => {
        
      //}, 500);

      return true;
    },
    ordenarAsc(p_array_json, p_key) {
      p_array_json.sort(function (a, b) {
        return a[p_key] < b[p_key];
      });
    },
    // Función para verificar si un elemento de arreglo2 está presente en arreglo1 y si su propiedad2 no es null
    validarElemento(elemento2) {
      console.log('validando elemento ' + elemento2.docmdoc);
      return this.billings.some(
        (elemento1) =>
          elemento1.docmdoc === elemento2.docmdoc &&
          elemento1.docmfelser !== null
      );
    },
    ordenarDesc(p_array_json, p_key) {
      p_array_json.sort(function (a, b) {
        return a[p_key] > b[p_key];
      });
    },
    async loadListValuesCiatab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCiatab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        console.log(error.message);
      }
      this.isLoading = false;
    },
    searchCiatab(event) {
      const companias = [];
      setTimeout(() => {
        for (const key in this.ciatab) {
          companias.push(
            this.ciatab[key].ciacod + ' - ' + this.ciatab[key].cianombre
          );
        }
        if (!event.query.trim().length) {
          this.filteredCiatab = [...companias];
        } else {
          this.filteredCiatab = companias.filter((country) => {
            return country.toLowerCase().includes(event.query.toLowerCase());
          });
        }
      }, 250);
    },
    clearValidity(input) {
      this[input].isValida = true;
    },
    async recoverFileforEmail(formData, refresh) {
      try {
        const response = await this.$store.dispatch(
          'billings/recoverFile',
          formData,
          {
            forceRefresh: refresh,
          }
        );

        console.log(response);
        const booleanValue = response.result === 'true';
        if (booleanValue) {
          //El enviaCorreo controla respuesta y muestra mensaje en pantalla
          this.enviaCorreo(formData, response.file);
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'NO se pudo recuperar archivo',
            detail:
              'El archivo del documento' +
              formData.docmdoc +
              ' no pudo ser recuperado!, intentar enviar nuevamente más tarde',
            //group: 'br',
            life: 4500,
          });
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary:
            'NO se pudo recuperar el archivo para ser enviado por correo, volver a intentar nuevamente mas tarde',
          detail: error,
          //group: 'br',
          life: 4500,
        });
        console.log(error);
      }
    },
  },
};
</script>
