<template>
  <Toast />
  <div>
    <div class="content-section implementation">
      <Toast position="bottom-center" group="bc">
        <template #message="slotProps">
          <div class="flex flex-column">
            <div class="text-center">
              <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
              <h4>{{ slotProps.message.summary }}</h4>
              <p>{{ slotProps.message.detail }}</p>
            </div>
            <div class="grid p-fluid">
              <div class="col-6">
                <Button
                  class="p-button-success"
                  label="Si"
                  @click="onConfirm(slotProps)"
                ></Button>
              </div>
              <div class="col-6">
                <Button
                  class="p-button-secondary"
                  label="No"
                  @click="onReject"
                ></Button>
              </div>
            </div>
          </div>
        </template>
      </Toast>
    </div>
  </div>

  <DataTable
    ref="dv"
    responsiveLayout="scroll"
    id="entregas"
    :value="deliveries"
    dataKey="delenv"
    :paginator="true"
    :rows="25"
    :loading="loading1"
    :filters="filters"
    :rowClass="rowClass"
    v-model:expandedRows="expandedRows"
    @rowExpand="onRowExpand"
    @rowCollapse="onRowCollapse"
    showGridlines
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[25, 50, 75, 100, 125]"
    currentPageReportTemplate="Viendo {first} a {last} de {totalRecords} entregas"
  >
    <template #header>
      <div
        class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between"
      >
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="filters['global'].value"
            placeholder="Buscar..."
            size="75"
          /><Button
            type="button"
            icon="pi pi-filter-slash"
            class="p-button-outlined"
            @click="initFilters()"
          />
        </span>
        <span class="p-input-icon-left">
          <Calendar
            id="calendar"
            v-model="fec_consulta"
            dateFormat="dd/mm/yy"
            placeholder="Fecha de Consulta"
            :showIcon="true"
            @date-select="onDateSelect"
            :showOnFocus="false"
            :touchUI="false"
            readonlyInput
            :manualInput="false"
          />
        </span>
      </div>
    </template>
    <template #empty><b>No se encontró ningúna entrega ingresada.</b></template>
    <template #loading> Cargando la consulta de envios... </template>
    <Column :expander="true" :headerStyle="{ width: '3rem' }" />
    <Column
      field="deldoc"
      header="No. Pedido"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delenv"
      header="No. Envío"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delnom"
      header="Nombre de Cliente"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delfecdoc2"
      header="Fecha de Entrega"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>

    <Column
      field="deldir"
      header="Dirección de Entrega"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="deltel"
      header="Teléfono"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <Column
      field="delmens"
      header="Agente de Logísitica Asignado"
      :sortable="true"
      style="min-width: 12rem"
    ></Column>
    <template #expansion="slotProps">
      <DataTable :value="slotProps.data.items">
        <Toolbar class="p-mb-4">
          <template v-slot:right>
            <div class="field-checkbox">
              <Checkbox
                id="binary"
                v-model="checked"
                @change="showTemplate(slotProps.data)"
                :binary="slotProps.data.checked"
                :disabled="slotProps.data.checked"
                class="custom-checkbox"
              />
              <label for="binary"><strong> Empacado?</strong></label>
            </div>
          </template>
          <template v-slot:left>
            <h5>
              <strong>Detalle del Envío:</strong> {{ slotProps.data.delenv
              }}<br />
              <strong>A nombre de:</strong> {{ slotProps.data.delnom }}<br />
              <strong>Dirección de Entrega:</strong> {{ slotProps.data.deldir }}
            </h5>
          </template>
        </Toolbar>

        <Column
          field="deldprd"
          header="Codigo"
          sortable
          style="text-align: right"
        ></Column>
        <Column header="Imagen">
          <template #body="slotProps">
            <img
              :src="slotProps.data.invptmpath"
              alt="Imagen del producto"
              class="zoomable-image"
              width="8px"
            />
          </template>
        </Column>
        <Column field="delddesc" header="Producto" sortable></Column>
        <Column
          field="deldunivta"
          header="Cantidad"
          sortable
          style="text-align: right"
        ></Column>
        <Column
          field="deldeprecaj"
          header="Precio"
          sortable
          style="text-align: right"
        >
        </Column>
        <Column
          field="deldvallin"
          header="Valor"
          sortable
          style="text-align: right"
        >
        </Column>
      </DataTable>
    </template>
  </DataTable>

  <Dialog
    v-model:visible="deleteProductDialog"
    :style="{ width: '450px' }"
    header="Confirmacion"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span v-if="product"
        >Esta seguro que desea eliminar el pedido No. <b>{{ product.deldoc }}</b
        >?</span
      >
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteProductDialog = false"
      />
      <Button
        label="Si"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteProduct"
      />
    </template>
  </Dialog>
  <!--DIALOGO DE CONFIRMACION AL MOMENTO DE MARCAR COMO EMPAQUETADO-->
  <Dialog
    header="Confirmación"
    v-model:visible="showDialog"
    :modal="true"
    :closable="true"
    :draggable="false"
  >
    <p>¿Estás seguro de que quieres continuar?</p>
    <div class="p-d-flex p-jc-end">
      <Button label="Sí" @click="confirm" class="p-button-success" />
      <Button label="No" @click="cancel" class="p-button-secondary" />
    </div>
  </Dialog>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import 'jspdf-autotable';
export default {
  data() {
    return {
      showDialog: false,
      checked: false,
      expandedRows: [],
      items: [
        {
          label: 'Generacion PDF',
          icon: 'pi pi-file-pdf',
          command: () => {
            this.openBasic();
          },
        },
        {
          label: 'Generacion TXT',
          icon: 'pi pi-file',
          command: () => {
            this.openBasicTxt();
          },
        },
      ],
      fec_consulta: null,
      delfecdoc: null,
      displayBasic: false,
      displayBasictxt: false,
      contador: 0,
      delcia: 0,
      deleteProductDialog: false,
      ciatab: null,
      isLoading: false,
      deliveries: null,
      ClientDialog: false,
      deliverie: {},
      selectedProducts: null,
      submitted: false,
      filters: null,
      loading1: false,
      filteredCiatab: null,
      mensajeros: null,
    };
  },

  /*
--MANEJO DE LA FECHA PARA EL UPDATE, NO CAMBIA.
--LISTAS DE VALORES NO CARGAN PARA EL UPDATE (listo).
--VERIFICAR CAMBIOS DE CHECKBOX
--VER PORQUE NO CARGANN SIEMPRE LA LISTA EN LA PRIMER PANTALLA
--CAMBIO DE LOGIN.*/
  created() {
    this.initFilters();

    this.loadListValuesMensajeros(true);
    this.loadListValuesPrioridades(true);
    this.loadListValuesCiatab(true);

    this.mensajeros = this.listValuesMensajeros;
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    //console.log('yo primero 2');
  },
  computed: {
    isLoggedIn() {
      ////console.log(this.$store.getters.isAuthenticated);
      return this.$store.getters.isAuthenticated;
    },
    hasDeliveries() {
      return this.$store.getters['deliveries/hasDeliveries'];
    },
    filtrarDeliveries() {
      const deliveries =
        this.$store.getters['deliveries/deliveries']['deliveries'];

      for (const key in deliveries) {
        if (deliveries[key].delfeldoc != 1) {
          deliveries[key].checked = false;
        } else {
          deliveries[key].checked = true;
        }

        console.log(this.checked);
        deliveries[key].delfecdoc = this.dateFormatOracle(
          deliveries[key].delfecdoc
        );
        for (const key2 in this.$store.getters['listvalues/listvalues'][
          'listvaluesmensajeros'
        ]) {
          if (
            deliveries[key].delmens ==
            this.$store.getters['listvalues/listvalues'][
              'listvaluesmensajeros'
            ][key2].menscod
          ) {
            deliveries[key].delmens =
              this.$store.getters['listvalues/listvalues'][
                'listvaluesmensajeros'
              ][key2].mensnom;
          }
        }
      }
      this.ordenarAsc(deliveries, 'delenv'); //es desc

      /*deliveries.sort((a, b) =>
        a.delenv > b.delenv ? 1 : b.delenv > a.delenv ? -1 : 0
      );
      deliveries.sort((a, b) =>
        b.delfecdoc > a.delfecdoc ? 1 : a.delfecdoc > b.delfecdoc ? -1 : 0
      );*/
      return deliveries;
    },

    listValuesMensajeros() {
      try {
        //console.log(
        // this.$store.getters['listvalues/listvalues']['listvaluesmensajeros']
        //);
        const mensajeros =
          this.$store.getters['listvalues/listvalues']['listvaluesmensajeros'];
        return mensajeros.filter((mensajeros) => {
          if (mensajeros.menscod != 0) {
            return true;
          }
          if (mensajeros.menscod != 0) {
            return true;
          }
          //console.log(mensajeros);

          return true;
        });
      } catch (error) {
        //console.log(error.messages);
        return true;
      }
    },
  },

  methods: {
    rowClass(data) {
      //metodo para pintar la filas
      return data.delfeldoc === 1 ? 'row-checked' : null;
    },
    onConfirm(itemSelected) {
      console.log(itemSelected);
      this.$toast.removeGroup('bc');
      this.updateDeliverie(itemSelected);
    },
    onReject() {
      this.$toast.removeGroup('bc');
    },
    showTemplate(itemSelected) {
      console.log(itemSelected);
      this.$toast.add({
        severity: 'warn',
        summary: 'Se encuentra seguro que fue empacado?',
        detail:
          "Si ha confirmado que se encuentra empacado por favor presionar 'Si'",
        cia: itemSelected.delcia,
        doc: itemSelected.deldoc,
        env: itemSelected.delenv,
        mens: itemSelected.delmens,
        fecdoc: itemSelected.delfecdoc,
        nom: itemSelected.delnom,
        dir: itemSelected.deldir,
        nit: itemSelected.delnit,
        tel: itemSelected.deltel,
        latitud:
          itemSelected.dellatitud === null ? '' : itemSelected.dellatitud,
        longitud:
          itemSelected.dellongitud === null ? '' : itemSelected.dellongitud,
        bultos: itemSelected.delbultos === null ? '' : itemSelected.delbultos,
        prioridad: itemSelected.delprioridad,
        flg: itemSelected.delflg,
        dispositivo:
          itemSelected.deldispositivo === null
            ? ''
            : itemSelected.deldispositivo,
        firma: itemSelected.delfirma === null ? '' : itemSelected.delfirma,
        uuid: itemSelected.deluuid === null ? '' : itemSelected.deluuid,
        felfec: itemSelected.delfelfec === null ? '' : itemSelected.delfelfec,
        feldoc: 1,
        felser: itemSelected.delfelser === null ? '' : itemSelected.delfelser,
        mmsg: itemSelected.delmmsg === null ? '' : itemSelected.delmmsg,
        group: 'bc',
      });
    },
    onCheckboxChange() {
      this.showDialog = true;
    },
    onInput(event) {
      // Filtra la entrada para permitir solo números y caracteres del formato (ej. "/")
      const validCharacters = /^[0-9/]*$/; // Elimina el escape innecesario en "/"

      if (!validCharacters.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9/]/g, ''); // Elimina el escape innecesario en "/"
      }
    },

    onDateSelect() {
      this.loading1 = true;
      // Ejecutar codigo al momento de seleccionar una fecha
      this.$toast.add({
        severity: 'success',
        summary: 'Fecha seleccionada',
        detail: `Seleccionaste: ${this.fec_consulta}`,
        life: 3000,
      });
      setTimeout(() => {
        console.log(this.dateFormatOracle2(this.fec_consulta));
        this.loadDeliveries(true, this.dateFormatOracle(this.fec_consulta));
      }, 3000);

      setTimeout(() => {
        while (this.deliveries.length === 0 || this.deliveries === null) {
          this.loadDeliveries(true, 1);

          this.contador++;
          if (this.contador === 2) {
            break;
          }
        }
      }, 4000);
    },
    onRowExpand(event) {
      console.log(event.data.checked);
      if (event.data.checked == true) {
        this.checked = true;
      } else {
        this.checked = false;
      }
      console.log(this.checked);
    },
    onRowCollapse(event) {
      console.log(event);
    },
    clearData() {
      this.deliveries = null;
    },

    ordenarAsc(p_array_json, p_key) {
      p_array_json.sort(function (a, b) {
        return a[p_key] < b[p_key];
      });
    },
    ordenarDesc(p_array_json, p_key) {
      p_array_json.sort(function (a, b) {
        return a[p_key] > b[p_key];
      });
    },
    closeBasic() {
      this.displayBasic = false;
    },
    openBasicTxt() {
      this.displayBasictxt = true;
    },
    openBasic() {
      this.displayBasic = true;
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async updateDeliverie(itemSelected) {
      for (const key4 in this.mensajeros) {
        console.log(this.mensajeros[key4].mensnom);
        console.log(itemSelected.message.mens);
        if (this.mensajeros[key4].mensnom === itemSelected.message.mens) {
          itemSelected.message.mens = this.mensajeros[key4].menscod;
        }
      }
      console.log(itemSelected.message.mens);
      const meses = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ];

      const [dia, mes, año] = itemSelected.message.fecdoc.split('/');
      const mesTexto = meses[parseInt(mes, 10) - 1]; // Restamos 1 porque los índices de los meses comienzan en 0
      itemSelected.message.fecdoc = `${dia}-${mesTexto}-${año}`;
      console.log(itemSelected.message.fecdoc);
      const response = await this.$store.dispatch(
        'deliveries/updateDeliveries',
        itemSelected.message
      );
      console.log(response);
      if (response.codigo == 1) {
        this.$toast.add({
          severity: 'success',
          summary: 'Empacado de ' + itemSelected.message.deldoc,
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Empacado de ' + itemSelected.message.deldoc,
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      }
      setTimeout(() => {
        this.loadDeliveries(true, this.dateFormatOracle(this.fec_consulta));
      }, 1000);
    },
    getMonthOracle(fecha) {
      //console.log(fecha);

      const date = new Date(fecha);
      //console.log(date);
      let month = date.getMonth() + 1;
      //let dt = date.getDate();

      //console.log(month);
      //console.log(dt);
      return month;
    },
    DeliveriesUpdateLink(product) {
      const redirectUrl =
        this.$route.path +
        '/' +
        product.delcia +
        '/' +
        product.deldoc +
        '/' +
        product.delnit +
        '/' +
        product.delenv +
        '/';

      this.$router.replace(redirectUrl);
      this.clientes = null;
    },
    dateFormatOracleUpdate(fecha, mes) {
      const date = new Date(fecha);
      //console.log(date);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '-' + mes + '-' + String(year).substr(2, 2);
    },
    dateFormatOracle2(fecha) {
      const date = new Date(fecha);
      date.setDate(date.getDate() + 1);

      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate() - 1;

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return year + '-' + month + '-' + dt;
    },
    dateFormatOracleUpdate2(fecha) {
      console.log(fecha);
      let dia = fecha.substr(0, 2);
      let mes = fecha.substr(3, 2);
      let year = fecha.substr(6, 4);
      if (parseInt(dia) > 12) {
        return year + '-' + mes + '-' + dia + ' 00:00:00';
      } else {
        return year + '-' + mes + '-' + dia + ' 00:00:00';
      }
    },
    dateFormatOracle(fecha) {
      console.log(fecha);
      const date = new Date(fecha);
      //const date2 = new Date(fecha);
      date.setDate(date.getDate() + 1);
      console.log(date);

      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return dt + '/' + month + '/' + year;
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async loadListValuesMensajeros(refresh = false) {
      this.isLoading = true;
      //console.log('mensajeros #1');
      try {
        await this.$store.dispatch('listvalues/loadListValuesMensajeros', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        //console.log(error.message);
      }
      this.isLoading = false;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      ////console.log(this.product);
      this.deleteProductDialog = true;
    },
    async deleteProduct() {
      ////console.log(this.product.cusname);
      ////console.log(this.product.cusnit);
      this.deleteProductDialog = false;
      const formData = {
        cia: this.product.delcia,
        nit: this.product.delnit,
        cod: this.product.deldoc,
        env: this.product.delenv,
      };
      ////console.log(formData.nit);
      const response = await this.$store.dispatch(
        'deliveries/deleteDeliveries',
        formData
      );
      this.clientes = null;
      this.ciacod = 0;
      console.log(response);
      if (response.codigo == 1) {
        this.$toast.add({
          severity: 'success',
          summary: 'Eliminacion ',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'Eliminacion',
          detail: response.mensaje,
          //group: 'br',
          life: 2000,
        });
      }
      this.loading1 = true;
      setTimeout(() => {
        this.loadDeliveries(true, 1);
        this.$router.replace('/Deliveries');
        this.loading1 = false;
      }, 3000);
    },

    async loadDeliveries(refresh = false, delfecdoc) {
      this.isLoading = true;
      this.deliveries = null;
      console.log(delfecdoc);
      try {
        await this.$store.dispatch(
          'deliveries/recuperarEnviosPorFecha',
          this.dateFormatOracle2(this.fec_consulta),
          {
            forceRefresh: refresh,
          }
        ); //viene de la clases action.js G
      } catch (error) {
        //console.log('loadDeliveries # errores');

        //console.log(error.message);
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      this.isLoading = false;
      this.deliveries = this.filtrarDeliveries;
      this.loading1 = false;
      let detail;

      try {
        for (const key in this.deliveries) {
          detail = await this.$store.dispatch(
            'deliveries/loadDetailDeliveriexDoc',
            this.deliveries[key],
            {
              forceRefresh: refresh,
            }
          ); //viene de la clases action.js G

          this.deliveries[key].items = detail;
          //console.log(this.billings[key])
        }
      } catch (error) {
        console.log(error);
      }
      return true;
    },
    registroNuevo() {
      const redirectUrl = '/' + (this.$route.query.redirect || 'newDeliveries');
      this.$router.replace(redirectUrl);
    },

    async loadListValuesPrioridades(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesPrioridades', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },

    async loadListValuesCiatab(refresh = false) {
      this.isLoading = true;
      try {
        await this.$store.dispatch('listvalues/loadListValuesCiatab', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        this.error = error.message || 'algo salió mal';
        ////console.log(error.message);
      }
      this.isLoading = false;
    },
    async trasladoDeliveries(refresh = false) {
      this.loading1 = true;
      this.isLoading = true;
      this.deliveries = null;
      try {
        await this.$store.dispatch('deliveries/trasladoDeliveries', {
          forceRefresh: refresh,
        }); //viene de la clases action.js G
      } catch (error) {
        //console.log('loadDeliveries # errores');

        //console.log(error.message);
        this.error = error.message || 'algo salió mal';
        this.isLoading = false;
      }
      setTimeout(() => {
        this.loadDeliveries(true, 1);
        this.deliveries = this.filtrarDeliveries;
      }, 2000);
      this.isLoading = false;
      this.deliveries = this.filtrarDeliveries;
      this.loading1 = false;
      return true;
    },
  },
};
</script>
<style scoped>
.center {
  border: 5px solid #ffffff;
  text-align: center;
}
.image-container {
  position: relative;
  width: 64px; /* Tamaño fijo para las imágenes en la tabla */
  height: 64px;
  overflow: hidden;
  cursor: pointer;
}

.zoomable-image {
  width: 64px; /* Tamaño de la imagen en la tabla */
  height: 64px; /* Tamaño de la imagen en la tabla */
  object-fit: cover; /* Asegura que la imagen se ajuste correctamente */
  transition: transform 0.2s ease; /* Animación suave */
}

.zoomable-image:hover {
  transform: scale(5); /* Nivel de zoom */
  z-index: 10; /* Para que la imagen esté por encima de otros elementos */
  position: absolute; /* Asegura que la imagen se posicione por encima */
  cursor: pointer; /* Indica que es interactiva */
}
button {
  min-width: 10rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 960px) {
  button {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

:deep(.row-checked) {
  background-color: rgba(7, 246, 7, 0.384) !important;
}

/* Contenedor del checkbox */
.field-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
}

.custom-checkbox .p-checkbox-box {
  width: 30px !important; /* Aumentar tamaño */
  height: 30px !important;
  border: 3px solid #007bff !important; /* Bordes azules intensos */
  background-color: #ffffff !important; /* Fondo blanco sólido */
  border-radius: 6px !important; /* Bordes redondeados */
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3); /* Sombra más fuerte */
  transition: all 0.2s ease-in-out; /* Animación suave */
}

/* Hover para el checkbox */
.custom-checkbox .p-checkbox-box:hover {
  background-color: #e0f7ff !important; /* Fondo más claro */
  border-color: #0056b3 !important; /* Bordes más oscuros */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4); /* Sombra más prominente */
}

/* Checkbox marcado */
.custom-checkbox .p-checkbox-box.p-highlight {
  background-color: #007bff !important; /* Fondo azul cuando marcado */
  border-color: #0056b3 !important;
}

/* Icono del checkbox */
.custom-checkbox .p-checkbox-icon {
  font-size: 20px !important; /* Ícono más grande */
  color: white !important; /* Ícono blanco */
}
</style>
